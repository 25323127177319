<template lang="pug">
    b-card.black-card(
        :header-class='item.ESTADO ? "success": "pending"'
        header-tag='header', footer='',
        footer-tag='footer'
        )
        modal-license(ref="modalLicense", :license='licenseToUpdate')
        modal-checkout(ref="modalCheckout", :license='licenseToUpdate')
        b-popover(:target="'popover-target-'+ item.CODIGO" triggers='hover' placement='top')
            template(#title='') Vehículo: {{ item.PLACA }}
            p Modelo: 
                span {{ item.MODELO }}
            p Marca: 
                span {{ item.MARCA }}
            p Aseguradora: 
                span {{ item.ASEGURADORA }}
            p Concesionario: 
                span {{ item.CONCESIONARIO }}
            p Broker: 
                span {{ item.BROKER }}
            p Estado: 
                span {{ item.ESTADO }}
        template(#header='')
            .icon-vehicle
                font-awesome-icon(:icon="carIcon")
            h3 {{ item.PLACA }}
                span Placa:  {{ item.ESTADO ? 'Vigente' : 'Pendiente de aprobación' }}
            .icon-header(@click="openModalLicense(item)")
                span
                    font-awesome-icon(:icon="faPenAltIcon")
                    | Editar placa
        //- p
        //-     span Modelo
        //-     | {{ item.MODELO }}
        //- p
        //-     span Aseguradora
        //-     strong.truncate {{ item.ASEGURADORA }}
        .text-center.mt-1.mb-4
            b-button.btn(@click="goProduct(item.CODIGO)") RENOVAR PRODUCTOS
            b-button.btn(@click="openModalCheckout(item)") Solicitar Revisión vehicular
            b-button(:id="'popover-target-'+ item.CODIGO") VER DETALLES
        p
            strong Marca       
            | {{ item.MARCA }}
</template>

<script>
import { faChevronRight, faCar, faInfoCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import modalLicense from '../editLicense'
import modalCheckout from '../checkout'
export default {
    data (){
        return {
            licenseToUpdate: ''
        }
    },
    props: {
        item: null
    },
    components: {
        'modal-license': modalLicense,
        'modal-checkout': modalCheckout,
    },
    computed: {
        chevronRightIcon () {
            return faChevronRight
        },
        carIcon () {
            return faCar
        },
        faInfoCircleIcon () {
            return faInfoCircle
        },
        faPenAltIcon () {
            return faPen
        }
    },
    methods: {
        openModalCheckout (val) {
            this.licenseToUpdate = val
            this.$refs.modalCheckout.open()
        },
        goProduct (id) {
            this.$router.push(`/productos/${id}`)
        },
        openModalLicense (val) {
            this.licenseToUpdate = val
            this.$refs.modalLicense.open()
        }
    }
}
</script>

<style lang="scss">
    @import './style.scss';
</style>