<template lang="pug">
    b-modal(v-model='modalShowCheckout', :hide-footer='true', :modal-class='[ "modal-checkout" ]', :centered='true', size='md', title='Solicitar revisión vehicular')
      template(v-if="!withoutEmail")
        .modal-license-container
          p Para poder solicitar la revisión de su vehículo debe completar el correo en su perfil.
          .text-right.mt-5
            b-button(type='submit' @click='close()'  variant='default') Cancelar
            b-button(type='button' @click='goProfile()' variant='primary') Ir a mi perfil
      template(v-else)
        .modal-license-container
          p Solicitar una revisión para tu vehículo identificado con la placa:
              strong  {{ license.PLACA }}.
              |  El equipo de Hunter se pondrá en contacto a tráves de tu correo y/o telefóno. Gracias. 
          .text-right.mt-5
              b-button(type='submit' @click='close()'  variant='default') Cancelar
              b-button(type='submit' @click='send()' :disabled="disabledBtn" variant='primary') Solicitar revisión
</template>

<script>
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'modalShowCheckout',
  props: {
    license: null
  },
  data () {
    return {
      modalShowCheckout: false,
      newLicense: '',
      disabledBtn: false
    }
  },
  computed: {
    ...mapState(['user']),
    faQuestionCircleIcon () {
      return faQuestionCircle
    },
    withoutEmail () {
      if (this.user?.CORREO) {
        return this.user.CORREO.length != 0
      }
      return false
    }
  },
  methods: {
    ...mapActions(['CheckoutVehicle', 'GetRequest']),
    open () {
      this.modalShowCheckout = true
    },
    close () {
      this.modalShowCheckout = false
    },
    send () {
        // this.CheckoutVehicleService(this.license)
        this.GetRequestReview()
    },
    goProfile () {
      this.$router.push('/perfil')
    },
    async GetRequestReview () {
      try {
        this.disabledBtn = true
        let dto = {
          "NRO_RECLAMO": 0,
          "CODIGO_TIPO": "001",
          "CODIGO_MOTIVO": "023",
          "CODIGO_SUB_MOTIVO": "001",
          "DESCRIPCION": "CLIENTE SOLICITA CHEQUEO",
          "CODIGO_USUARIO": "ADMIN",
          "CODIGO_CLIENTE": this.user.ID_CLIENTE,
          "CODIGO_VEHICULO": this.license.CODIGO,
          "OP": 1
        }

        const res = await this.GetRequest(dto)
        if(res.status == 200) {
          console.log(res)
          this.CheckoutVehicleService(res.data.registros)
        }

      } catch (error) {
        console.log(error)
      } finally {
        this.disabledBtn = false
      }
    },
    async CheckoutVehicleService (val) {
      try {
        this.disabledBtn = true
        let dto = {
          usuario: this.user.PRIMER_NOMBRE + ' ' + this.user.APELLIDO_PATERNO,
          id_vehiculo: this.license.CODIGO,
          placa: this.license.PLACA,
          emails: this.user.CORREO,
          mensaje_adicional: `NRO_REQUERIMIENTO: ${val[0].NRO_RECLAMO} Su solicitud ha sido enviada, nos pondremos en contacto con usted en un lapso de 24 horas.`
        }
        const res = await this.CheckoutVehicle(dto)
        if(res.status == 200) {
          this.$bvModal.msgBoxOk('Solicitud enviada.', {
            title: 'Hunter',
            centered: true
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.disabledBtn = false
        this.modalShowCheckout = false
      }
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
