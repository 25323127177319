<template lang="pug">
  b-modal(v-model='modalShowLicense', :hide-footer='true', :modal-class='[ "modal-license" ]', :centered='true', size='md', title='Solicitar cambio de placa')
    .modal-license-container
      p Cambiar la placa del vehículo:
        strong  {{ license.PLACA }}
        |  por la siguiente:
      b-form-group(label='' label-for='input-1')
        b-form-input(type="text", v-model='$v.newLicense.$model', placeholder='Ingresa nueva placa')
        .error.text-left(v-if="(!$v.newLicense.required && $v.newLicense.$anyDirty)") Campo obligatorio
        .error.text-left(v-if="!$v.newLicense.minLength && $v.newLicense.$anyDirty") Ingrese {{$v.newLicense.$params.minLength.min}} como mínimo.
      p(style="color: #636363; line-height: 1.3")
        strong Hunter 
        | evaluará el cambio e indicara su conformidad por correo y/o telefóno del usuario. Esto podría tomar algunos días. Para mayor información ponganse en contacto con nosotros a través de nuestro teléfono 
        a(href="tel:016185930") (01) 618-5930
        |  o al siguiente correo 
        a(href="mailto:atencionalcliente@hunterlojack.com") atencionalcliente@hunterlojack.com
    .text-right.mt-5
      b-button(type='submit' @click='close()'  variant='default') Cancelar
      b-button(type='submit' @click='send()' variant='primary') Solicitar cambio
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'modalLicense',
  props: {
    license: null
  },
  data () {
    return {
      modalShowLicense: false,
      newLicense: ''
    }
  },
  validations: {
    newLicense: {
      required,
      minLength: minLength(6)
    }
  },
  methods: {
    ...mapActions(['GetListVehicle', 'EditLicense']),
    open () {
      this.modalShowLicense = true
    },
    close () {
      this.modalShowLicense = false
      this.newLicense =  ""
      this.$v.$reset()
    },
    send () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('Validar formulario')
      } else {
        this.editLicenseService()
      }
    },
    async editLicenseService () {
      try {
        const datos = {
          placa: this.license.PLACA,
          codigo_vehiculo: this.license.CODIGO,
          codigo_aseguradora: this.license.CODIGO_ASEGURADORA,
          codigo_broker: this.license.CODIGO_BROKER
        }
        const res = await this.EditLicense(datos)
        console.log(res.data)
        if (res.status === 200) {
          this.$bvModal.msgBoxOk('Solicitud de cambio de PLACA enviada.', {
            title: 'Hunter',
            centered: true
          })
            .then(value => {
              console.log('actualizado correctamente')
            })
            .catch(err => {
              console.log(err)
            })
        }
      } catch {
        console.log('Error')
      } finally {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
