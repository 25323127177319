<template lang="pug">
  .home
    side-bar
    alert-bar
    title-page(firstTitle='Mis vehiculos', subTitle='Control vehicular')
    .container-bg
      b-row
        b-row
          b-col.form-search(md='4')
            //- v-if='vehicles.length > 1'
            b-form-group.mb-4(label='' label-for='input-1')
                b-form-input(type="search", v-model='search', placeholder='Búsqueda por placa, marca o modelo...')
          b-col(md='8')
              h6.text-right.products-total Tienes
                  strong  {{ vehicles.length }}
                  |  vehículo(s) asociado(s).
      b-row
        b-col(md='4', xs='12', v-for='item in searchVehicle' :key='item.CODIGO')
          vehicle-component(:item="item")
      b-row(v-if="searchVehicle.length == 0")
        notResults
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Title from '../components/title'
import Vehicle from '../components/vehicle'
import notResults from '../components/notResultsComponent'
export default {
  name: 'Home',
  data () {
    return {
      search: ''
    }
  },
  components: {
    'title-page': Title,
    'vehicle-component': Vehicle,
    notResults
  },
  computed: {
    ...mapState(['vehicles', 'user']),
    searchVehicle () {
      let texto = this.search.toLowerCase()
      if(texto.length != 0) {
        const filtro = this.vehicles.filter(vehicle => {
          const placa = vehicle.PLACA.toLowerCase()
          const modelo = vehicle.MODELO.toLowerCase()
          const marca = vehicle.MARCA.toLowerCase()
          if(placa.includes(this.search.toLowerCase())
          || marca.includes(this.search.toLowerCase())
          || modelo.includes(this.search.toLowerCase())) {
            return vehicle
          }
        })
        console.log(filtro)
        return filtro;
      } else {
        return this.vehicles
      }
    }
  },
  watch: {
    user (val) {
      this.validateAuth()
    }
  },
  methods: {
    ...mapActions(['GetListVehicle', 'CheckoutVehicle']),
    validateAuth() {
      if (this.user != null) {
        this.GetListVehicle()
      }
    },
    
    
  },
  created() {
    this.validateAuth()
  }
}
</script>
